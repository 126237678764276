
.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.play-button {
  font-size: 2rem;
}
.pause-button {
  font-size: 2rem;
}
.sliderControls {
  display: flex;
  align-items: center;
}

.right-arrow {
  padding-top: 1rem;
  font-size: 3rem;
  cursor: pointer;
}

.left-arrow {
  padding-top: 1rem;
  font-size: 3rem;
  cursor: pointer;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  max-width: 60%;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media only screen and (max-width: 767px) {
  .slide {
    max-width: 85%;
  }
  .right-arrow {
    font-size: 3rem;
  }
  .left-arrow {
    font-size: 3rem;
  }
}

.github-button {
  position: relative;
}
